<template>
  <v-card
    :color="colorHeader"
    class="elevation-7 pa-4"
    :loading="dataUploadLoading"
  >
    <template slot="progress">
      <v-progress-linear height="10" indeterminate></v-progress-linear>
    </template>
    <v-card-title> CSV / xslx Datei für den Upload auswählen. </v-card-title>
    <v-card-subtitle class="font-weight-bold text-subtitle-1">
      {{ errorMessage }}
    </v-card-subtitle>
    <v-file-input
      v-model="fileToParse"
      label="Bitte CSV/ Excel Datei Auswählen"
      @change="ParseVSCToJson"
    >
    </v-file-input>
    <v-btn
      block
      class="mt-2"
      color="green"
      @click="extractDatafrom2DArray"
      :loading="dataUploadLoading"
      :disabled="uploadButtonInactive"
      >Datei Hoschladen und in Datenbank eintragen</v-btn
    >
    <v-dialog v-model="agreeDialog" persistent width="70%">
      <v-card>
        <v-card-title class="text-h5">
          Bitte überprüfen sie die Daten aus der Datei
        </v-card-title>
        <v-card-text>
          <p class="text-center text-h6">Jahr: {{ yearFromFile }}</p>
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="id"
            style="height: auto"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="agreeDialog = false">
            Nein Bitte zurück
          </v-btn>
          <v-btn color="green" text @click="uploadToApi">
            Ja sieht gut aus
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";

export default {
  data() {
    return {
      data: {},
      items: [],
      headers: [
        { text: "KW", value: "id", align: "center" },
        {
          text: "Umsatz Obst u. Gemüse",
          value: "umsatzObstGem",
          align: "center",
        },
        { text: "Umsatz Sonstiges", value: "umsatzSonst", align: "center" },
        { text: "Umsatz Kombiniert", value: "umsatz", align: "center" },
        { text: "Anzahl Bestellungen", value: "nrOfOrders", align: "center" },
      ],
      yearFromFile: null,
      errorMessage: "",
      colorHeader: "gray",
      dataUploadLoading: false,
      uploadButtonInactive: true,
      fileToParse: null,
      agreeDialog: false,
    };
  },
  props: {
    apiRouteSet: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
  },
  methods: {
    ParseVSCToJson: async function () {
      try {
        this.items = [];
        this.generateDataelem();
        this.data = {};
        this.colorHeader = "white";
        this.dataUploadLoading = false;
        this.uploadButtonInactive = false;
        if (this.fileToParse === null) {
          this.uploadButtonInactive = true;
          return;
        }
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        reader.onload = (e) => {
          /* Parse data */
          const ab = e.target.result;
          const wb = XLSX.read(new Uint8Array(ab), { type: "array" });
          for (let i = 0; i < wb.SheetNames.length; i++) {
            /* Get Worksheet on pos i */
            const wsname = wb.SheetNames[i];
            const ws = wb.Sheets[wsname];
            /* Convert to array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Save in Data object */
            this.data[wsname] = data;
          }
        };
        await reader.readAsArrayBuffer(this.fileToParse);
      } catch (error) {
        this.uploadButtonInactive = true;
        this.errorMessage = "File Kann nicht Gelesen werden.";
        this.colorHeader = "red lighten-1";
      }
    },
    uploadToApi: async function () {
      this.dataUploadLoading = true;
      this.agreeDialog = false;
      const res = await axios.post(
        this.$store.state.apiUrl + this.apiRouteSet,
        //"/api/setDataUmsatzmonitor/setCompaniDataYear",
        {
          year: this.yearFromFile,
          data: this.items,
          email: this.email,
        }
      );
      console.log(res);
      if (res.status === 200) {
        this.errorMessage =
          "Upload von " + this.fileToParse.name + " erflogreich";
        this.fileToParse = null;
      } else {
        this.errorMessage = "Upload fehlgeschlagen";
      }
      this.dataUploadLoading = false;
    },
    extractDatafrom2DArray: function () {
      this.agreeDialog = true;
      //Neue und Alte Position des Jahres testen
      if (this.data["Betriebsdaten"][1][4] !== undefined) {
        this.yearFromFile = this.data["Betriebsdaten"][1][4];
      } else if (this.data["Betriebsdaten"][2][4] !== undefined) {
        this.yearFromFile = this.data["Betriebsdaten"][2][4];
      } else {
        this.errorMessage =
          "Jahresangabe fehlt im File oder ist an der falschen Stelle.";
        this.agreeDialog = false;
        this.colorHeader = "red lighten-1";
        this.uploadButtonInactive = true;
      }
      for (let key in this.data) {
        if (key.match(/[\w\W]{1,}Q[1-4]$/gm) === null) {
          continue;
        }
        let rowKW = 11;
        //In der alten Version ist der KW index in zeile 12 nicht in 11
        if (this.data[key][12][3] !== undefined) {
          if (
            typeof this.data[key][12][3] === "string" ||
            this.data[key][12][3] instanceof String
          ) {
            if (this.data[key][12][3].match(/KW[1-9]{1,2}$/gm) != null) {
              rowKW = 12;
            }
          }
        }

        for (let i = 0; this.data[key][rowKW].length > i; i++) {
          let weekIndex = null;
          try {
            weekIndex = Number(this.data[key][rowKW][i].replace(/KW/g, ""));
          } catch {
            continue;
          }
          if (Number.isInteger(weekIndex)) {
            this.items[weekIndex - 1].umsatzObstGem = Math.round(
              this.data[key][rowKW + 1][i]
            );
            this.items[weekIndex - 1].umsatzSonst = Math.round(
              this.data[key][rowKW + 2][i]
            );
            this.items[weekIndex - 1].umsatz = Math.round(
              this.data[key][rowKW + 3][i]
            );
            this.items[weekIndex - 1].nrOfOrders = Math.round(
              this.data[key][rowKW + 4][i]
            );
          }
        }
      }
    },
    generateDataelem: function () {
      let dataelem = [];
      for (let i = 1; i <= 53; i++) {
        dataelem.push({
          id: i,
          umsatzObstGem: null,
          umsatzSonst: null,
          umsatz: 0,
          nrOfOrders: null,
        });
      }
      this.items = dataelem;
    },
  },
  mounted() {
    this.generateDataelem();
  },
};
</script>

<style></style>
