<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">Überblick</p>
        <p class="text-center font-weight-light text-h4">
          {{ this.$store.state.UserData.companyName }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showRegisterTable">
      <v-col>
        <v-btn block color="primary" to="/ImportData">
          Neue daten Eintragen
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(post, index) in posts" :key="post.title">
      <v-col v-if="expandPosts || index < 1">
        <startpage-post :post="post"></startpage-post>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="posts.length > 0">
        <v-btn block color="secondary" @click="showAllPosts()">
          {{ btnExpandMSG }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4">
          <v-card-title>
            Tutorial zur Einführung für die erste Benutzung
          </v-card-title>
          <v-card-text>
            <a
              href="https://www.youtube.com/embed/l7iJCf-85L4"
              target="_blank"
              style="text-decoration: none"
            >
              <v-img src="@/assets/Tutorial-thumbdnail.png">
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-icon size="100">mdi-play-circle</v-icon>
                </v-row>
              </v-img>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-entry-overview :isAdmin="false" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <data-pool-overview />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataEntryOverview from "../components/evaluationCharts/DataEntryOverview.vue";
import DataPoolOverview from "../components/evaluationCharts/DataPoolOverview.vue";
import StartpagePost from "../components/StartpagePost.vue";
const axios = require("axios");

export default {
  components: { DataEntryOverview, DataPoolOverview, StartpagePost },
  name: "Home",
  data() {
    return {
      posts: [],
      expandPosts: false,
      btnExpandMSG: "Alle Blogbeiträge Anzeigen",
      showRegisterTable: true,
    };
  },
  methods: {
    showAllPosts: function () {
      if (this.expandPosts) {
        this.expandPosts = false;
        this.btnExpandMSG = "Alle Blogbeiträge Anzeigen";
      } else {
        this.expandPosts = true;
        this.btnExpandMSG = "Blogbeiträge Verstecken";
      }
    },
  },
  async mounted() {
    try {
      const res = await axios.get(
        this.$store.state.apiUrl + "/api/posts/getAllPosts"
      );
      if (res.data.allPosts != undefined) {
        this.posts = res.data.allPosts;
      }

      //go thru all posts and add the api pathe at the beginning of the image url
      for (let i = 0; i < this.posts.length; i++) {
        if (this.posts[i].image != undefined) {
          this.posts[i].imageUrl =
            this.$store.state.apiUrl + this.posts[i].imageUrl;
        }
      }

      axios
        .post(this.$store.state.apiUrl + "/api/admin/requestAcces")
        .then((res) => {
          if (res.status === 200) {
            this.showRegisterTable = false;
          }
        })
        .catch(() => {
          //its ok
        });
    } catch (e) {
      //nothing to catch
    }
  },
};
</script>
