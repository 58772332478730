<template>
  <v-container class="print-body" ref="reportElem">
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Datenvisualisierung Umsatzmonitor
        </p>
        <p class="text-center font-weight-light text-h4">
          {{ this.$store.state.UserData.companyName }}
        </p>
      </v-col>
    </v-row>
    <v-row class="hide-at-print">
      <v-col>
        <v-card class="pa-4">
          <v-card-title> Jahr für Visualisierung auswählen </v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedYear"
              :items="generateYearsToSelectArray()"
              solo
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <year-comparison-chart :isAdmin="false" />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <data-current-year-comp-to-others
          :isAdmin="false"
          :selectedUser="this.$store.state.UserData"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <!--
    <v-row class="new-page">
      <v-col>
        <trend-line-comp-to-others
          :isAdmin="false"
          :selectedUser="this.$store.state.UserData"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row> 
    -->
    <v-row class="new-page">
      <v-col>
        <comp-tow-years-to-others
          :isAdmin="false"
          :selectedUser="this.$store.state.UserData"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <comp-tow-years
          :isAdmin="false"
          :selectedUser="this.$store.state.UserData"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <comp-tow-years-quartal
          :isAdmin="false"
          :selectedUser="this.$store.state.UserData"
          :selectedYear="selectedYear"
        />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <cart-value-years-comprison :isAdmin="false" />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <cart-value-years-comparison-quartal :isAdmin="false" />
      </v-col>
    </v-row>
    <v-row class="new-page">
      <v-col>
        <current-year-chart :isAdmin="false" :selectedYear="selectedYear" />
      </v-col>
    </v-row>
    <v-btn
      class="mt-5 hide-at-print"
      color="primary"
      :loading="exportLoading"
      block
      @click="exportPDF"
    >
      Bericht als PDF Speichern</v-btn
    >
  </v-container>
</template>

<script>
import YearComparisonChart from "../components/evaluationCharts/YearComparisonChart.vue";
import CartValueYearsComprison from "../components/evaluationCharts/CartValueYearsComprison.vue";
import CartValueYearsComparisonQuartal from "../components/evaluationCharts/CartValueYearsComparisonQuartal.vue";
import CurrentYearChart from "../components/evaluationCharts/CurrentYearChart.vue";
import DataCurrentYearCompToOthers from "../components/evaluationCharts/DataCurrentYearCompToOthers.vue";
import CompTowYears from "../components/evaluationCharts/CompTowYears.vue";
import CompTowYearsQuartal from "../components/evaluationCharts/CompTowYearsQuartal.vue";
import CompTowYearsToOthers from "../components/evaluationCharts/CompTowYearsToOthers.vue";
//import TrendLineCompToOthers from "../components/evaluationCharts/TrendLineCompToOthers.vue";

export default {
  components: {
    YearComparisonChart,
    CartValueYearsComprison,
    CartValueYearsComparisonQuartal,
    CurrentYearChart,
    DataCurrentYearCompToOthers,
    CompTowYears,
    CompTowYearsQuartal,
    CompTowYearsToOthers,
    //TrendLineCompToOthers,
  },
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      exportLoading: false,
    };
  },
  methods: {
    generateYearsToSelectArray: function (
      yearPreset = new Date().getFullYear()
    ) {
      let years = [];
      for (let i = 0; i < 7; i++) {
        years.push(yearPreset - i);
      }
      return years;
    },
    exportPDF: async function () {
      window.print();
    },
  },
  async mounted() {},
};
</script>

<style>
@media print {
  canvas {
    max-width: 100%;
    width: auto !important;
    size: landscape;
  }
  .new-page {
    page-break-before: always !important;
  }
  .hide-at-print {
    display: none !important;
  }
}
@page {
  size: landscape !important;
}
</style>
