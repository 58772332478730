<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Dateneingabe für den Umsatzmonitor
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <input-table-ums-mon
          apiRouteSet="/api/setDataUmsatzmonitor/setCompaniDataYear"
          apiRouteGet="/api/getDataUmsatzmonitor/getCompaniDataYear"
        >
        </input-table-ums-mon>
      </v-col>
      <v-col>
        <csv-input-ums-mon
          apiRouteSet="/api/setDataUmsatzmonitor/setCompaniDataYear"
        ></csv-input-ums-mon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CsvInputUmsMon from "../components/csvInputUmsMon.vue";
import InputTableUmsMon from "../components/InputTableUmsMon.vue";

export default {
  components: { InputTableUmsMon, CsvInputUmsMon },
  name: "ImportData",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style></style>
