<template>
  <v-container>
    <v-row class="my-10">
      <v-col cols="12">
        <p class="text-center font-weight-light text-h3">
          Unternehmensbezogene Informationen aktualisieren
        </p>
      </v-col>
    </v-row>
    <v-card>
      <v-row>
        <v-col>
          <v-card-title class="justify-center mt-7 mb-8">
            <v-avatar size="56" color="lightbg">
              {{ getFirstLetters(this.$store.state.UserData.companyName) }}
            </v-avatar>
          </v-card-title>
        </v-col>
      </v-row>
      <v-card-text
        v-for="userInfo in userInfos"
        :key="userInfo.index"
        class="justify-center align-center"
      >
        <v-row>
          <v-col class="pl-15" cols="10">
            <v-text-field
              outlined
              v-model="userInfo.variable"
              :label="userInfo.description"
              :disabled="userInfo.disabledInput"
            ></v-text-field>
          </v-col>
          <v-col class="pt-5">
            <v-btn
              block
              @click="editEntry(userInfo)"
              :color="userInfo.disabledInput ? '' : 'success'"
              :loading="userInfo.loading"
            >
              {{ userInfo.disabledInput ? "Bearbeiten" : "Speichern" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="justify-center align-center">
        <v-row>
          <v-col class="pl-15" cols="10">
            <v-switch
              :disabled="notification.disabledInput"
              v-model="notification.variable"
              label="Wöchentliche E-Mail Erinnerungen"
              inset
            ></v-switch>
          </v-col>
          <v-col class="pt-5">
            <v-btn
              block
              :color="notification.disabledInput ? '' : 'success'"
              @click="editEntry(notification)"
              :loading="notification.loading"
            >
              {{ notification.disabledInput ? "Bearbeiten" : "Speichern" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="justify-center align-center">
        <v-row>
          <v-col class="pl-15" cols="10">
            <v-switch
              v-model="$vuetify.theme.dark"
              label="Darkmode"
              @change="themeToCoockie()"
              inset
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text color="warning">
        {{ errorMessage }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AccountSettings",
  data() {
    return {
      errorMessage: "",
      userInfos: [
        {
          index: 0,
          variable: this.$store.state.UserData.companyName,
          variableName: "companyName",
          description: "Firmenname",
          disabledInput: true,
          loading: false,
        },
        {
          index: 1,
          variable: this.$store.state.UserData.email,
          variableName: "email",
          description: "E-Mail Adresse",
          disabledInput: true,
          loading: false,
        },
      ],
      notification: {
        index: 2,
        variable: this.$store.state.UserData.emailNotification,
        variableName: "emailNotification",
        description: "Erinnerungs ",
        disabledInput: true,
        loading: false,
      },
    };
  },
  methods: {
    getFirstLetters: function (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word[0])
        .join("");

      return firstLetters.substring(0, 3);
    },
    async editEntry(infoObj) {
      if (infoObj.disabledInput) {
        infoObj.disabledInput = false;
      } else {
        try {
          infoObj.loading = true;
          let user = this.$store.state.UserData;
          let sendableObj = {};
          sendableObj[infoObj.variableName] = infoObj.variable;
          const res = await axios.post(
            this.$store.state.apiUrl + "/api/users/changeAccountInfo",
            sendableObj
          );
          if (res.data === "success") {
            user[infoObj.variableName] = infoObj.variable;
            this.$store.dispatch("setUser", user);
            infoObj.disabledInput = true;
          } else {
            infoObj.variable = user[infoObj.variableName];
            infoObj.disabledInput = true;
            this.errorMessage = "Something went wrong";
          }
        } catch (e) {
          this.errorMessage = "Something went wrong";
          console.log(e);
        }

        infoObj.loading = false;
      }
    },
    async themeToCoockie() {
      document.cookie = "darkTheme=" + this.$vuetify.theme.dark;
    },
  },
};
</script>

<style></style>
