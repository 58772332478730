<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Entwicklung des eigenen Betriebes von {{ yearToCompare }} zu
      {{ selectedYear }} und im Vergleich zum Durchschnitt
    </v-card-title>
    <v-card-text>
      <v-select
        label="Vergleichsjahr wählen"
        class="hide-at-print"
        v-model="yearToCompare"
        :items="generateYearsToSelectArray(selectedYear - 1)"
        @change="setupChartData()"
        outlined
      />
      <v-select
        label="Datengrundlage"
        class="hide-at-print"
        v-model="variableToCompare"
        :items="choosableVariables"
        item-value="variable"
        item-text="description"
        @change="setupChartData()"
        outlined
      />
      <v-spacer />
      <custom-chart v-if="!loadingData" :chartdata="cahrtData" unit=" %" />
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      yearToCompare: this.selectedYear - 1,
      variableToCompare: "growthSumme",
      choosableVariables: [
        {
          description: "Umsatzentwicklung",
          variable: "growthSumme",
        },
        {
          description: "Umsatzentwicklung in der Kategorie Sonstiges",
          variable: "growthNettoUmsSonstige",
        },
        {
          description:
            "Umsatzentwicklung in der Kategorie Obst, Gemüse und Kartoffeln",
          variable: "growthNettoUmsObstGemKartof",
        },
        {
          description: "Entwicklung der Auftragszahlen",
          variable: "growthNumberOfOrders",
        },
      ],
    };
  },
  components: {
    customChart,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedUser: async function () {
      await this.setupChartData();
    },
    selectedYear: async function () {
      await this.setupChartData();
      if (this.selectedYear === this.yearToCompare) {
        this.yearToCompare = this.selectedYear - 1;
      }
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/"; // for own req: getCompaniDataCompTowYears
      }
      return "/api/getDataUmsatzmonitor/"; // for Average: getAverageDataCompTowYears + for own req: getCompaniDataCompTowYears
    },
    generateYearsToSelectArray: function () {
      let years = [];
      for (let i = 1; i < 8; i++) {
        years.push(this.selectedYear - i);
      }
      return years;
    },
    setupChartData: async function () {
      this.loadingData = true;

      //Größen Wörterbuch bereitstellen:
      let companySizeDictionary = {
        Big: "Große",
        Medium: "Mittelgroße",
        Small: "Kleine",
      };

      //Region Wörterbuch bereitstellen:
      let companyRegionDictionary = {
        South: "Süden",
        North: "Norden",
      };

      //Größenfattegorie ins Deutsche Übersetzen
      let companySizeDE = companySizeDictionary[this.selectedUser.companySize];

      //Region ins Deutsche Übersetzen
      let regionDE = companyRegionDictionary[this.selectedUser.region];

      let dataReq = [];
      dataReq.push(
        {
          apiQuerryPath: this.apiPath + "getCompaniDataCompTowYears",
          apiQuerryData: {
            year: this.selectedYear,
            yearToComp: this.yearToCompare,
            email: this.selectedUser.email,
          },
          labels: [this.selectedUser.companyName],
          subVariable: [this.variableToCompare],
        },
        {
          apiQuerryPath: this.apiPath + "getAverageDataCompTowYears",
          apiQuerryData: {
            year: this.selectedYear,
            yearToComp: this.yearToCompare,
            email: this.selectedUser.email,
            filter: {
              thresholdAverage: 8,
            },
          },
          labels: ["Ø Alle"],
          subVariable: [this.variableToCompare],
          tooltipInfo: {
            variable: "count",
            appendText: " Einträge ergeben diesen Durchschnitt",
          },
        },
        {
          apiQuerryPath: this.apiPath + "getAverageDataCompTowYears",
          apiQuerryData: {
            year: this.selectedYear,
            yearToComp: this.yearToCompare,
            email: this.selectedUser.email,
            filter: {
              filterOnCompanySize: true,
            },
          },
          labels: ["Ø " + companySizeDE + " Unternehmen"],
          subVariable: [this.variableToCompare],
          tooltipInfo: {
            variable: "count",
            appendText: " Einträge ergeben diesen Durchschnitt",
          },
        },
        {
          apiQuerryPath: this.apiPath + "getAverageDataCompTowYears",
          apiQuerryData: {
            year: this.selectedYear,
            yearToComp: this.yearToCompare,
            email: this.selectedUser.email,
            filter: {
              filterOnRegion: true,
            },
          },
          labels: ["Ø " + regionDE],
          subVariable: [this.variableToCompare],
          tooltipInfo: {
            variable: "count",
            appendText: " Einträge ergeben diesen Durchschnitt",
          },
        },
        {
          apiQuerryPath: this.apiPath + "getAverageDataCompTowYears",
          apiQuerryData: {
            year: this.selectedYear,
            yearToComp: this.yearToCompare,
            email: this.selectedUser.email,
            filter: {
              filterOnFederalState: true,
            },
          },
          labels: ["Ø " + this.selectedUser.federalState],
          subVariable: [this.variableToCompare],
          tooltipInfo: {
            variable: "count",
            appendText: " Einträge ergeben diesen Durchschnitt",
          },
        }
      );
      //console.log(this.cahrtData);
      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },

  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
