<template>
  <v-card color="gray" class="elevation-7 pa-4" :loading="dataUploadLoading">
    <template slot="progress">
      <v-progress-linear height="10" indeterminate></v-progress-linear>
    </template>
    <v-card-title>
      Manuelle Eingabe der Umsatzmonitordaten bis zur <br />
      aktuellen Kalenderwoche
    </v-card-title>
    <v-card-subtitle>
      Bitte alle Daten bis zur aktuellen Kalenderwoche eintragen
    </v-card-subtitle>
    <v-select
      :items="years"
      v-model="selectedYear"
      outlined
      label="Jahr"
      @change="generate_items_array"
    ></v-select>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      style="height: auto"
      v-if="!dataUploadLoading"
      :page="currentPage"
      :itemsPerPage="itemsPerPage"
    >
      <template v-slot:[`item.umsatzObstGem`]="props">
        <v-text-field
          @change="addUmsatz(props.item)"
          v-model="props.item.umsatzObstGem"
          prefix="€"
          class="mt-3 centered-input"
          type="number"
        ></v-text-field>
      </template>
      <template v-slot:[`item.umsatzSonst`]="props">
        <v-text-field
          @change="addUmsatz(props.item)"
          v-model="props.item.umsatzSonst"
          prefix="€"
          class="mt-3 centered-input"
          type="number"
        ></v-text-field>
      </template>
      <template v-slot:[`item.nrOfOrders`]="props">
        <v-text-field
          v-model="props.item.nrOfOrders"
          class="mt-3 centered-input"
          type="number"
        ></v-text-field>
      </template>
    </v-data-table>
    <v-btn
      block
      class="mt-2"
      color="green"
      @click="uploadDataToAPI"
      :loading="dataUploadLoading"
    >
      Daten abschicken
    </v-btn>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "InputTableUmsMon",
  data() {
    return {
      headers: [
        {
          text: "Woche",
          value: "id",
          align: "center",
        },
        {
          text: "Umsatz Obst, Gemüse, Kartoffeln",
          value: "umsatzObstGem",
          align: "center",
        },
        {
          text: "Umsatz Sonstige Produkte",
          value: "umsatzSonst",
          align: "center",
        },
        {
          text: "Summe Umsatz",
          value: "umsatz",
          align: "center",
        },
        {
          text: "Anzahl Aufträge",
          value: "nrOfOrders",
          align: "center",
        },
      ],
      items: [
        {
          id: "Server Kann nicht erreicht werden",
          umsatzObstGem: null,
          umsatzSonst: null,
          umsatz: 0,
          nrOfOrders: null,
        },
      ],
      years: [],
      selectedYear: new Date().getFullYear(),
      dataUploadLoading: true,
      currentPage: 2,
      itemsPerPage: 10,
    };
  },
  props: {
    apiRouteGet: {
      type: String,
      required: true,
    },
    apiRouteSet: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
  },
  methods: {
    addUmsatz(elem) {
      if (elem.umsatzSonst === null) {
        elem.umsatzSonst = 0;
      } else if (elem.umsatzObstGem === null) {
        elem.umsatzObstGem = 0;
      }
      elem.umsatzSonst = parseInt(elem.umsatzSonst);
      elem.umsatzObstGem = parseInt(elem.umsatzObstGem);
      elem.umsatz = parseInt(elem.umsatzSonst) + parseInt(elem.umsatzObstGem);
    },
    async generate_items_array() {
      let dataelem = [];
      for (let i = 1; i <= 53; i++) {
        dataelem.push({
          id: i,
          umsatzObstGem: null,
          umsatzSonst: null,
          umsatz: 0,
          nrOfOrders: null,
        });
      }

      const res = await axios.post(
        this.$store.state.apiUrl + this.apiRouteGet,
        //"/api/getDataUmsatzmonitor/getCompaniDataYear",
        {
          year: this.selectedYear,
          email: this.email,
        }
      );
      for (let i = 0; i < res.data.weeksArray.length; i++) {
        let weekElem = res.data.weeksArray[i];
        dataelem[weekElem.kw - 1] = {
          id: weekElem.kw,
          umsatzObstGem: weekElem.nettoUmsObstGemKartof,
          umsatzSonst: weekElem.nettoUmsSonstige,
          umsatz: weekElem.summe,
          nrOfOrders: weekElem.numberOfOrders,
        };
      }

      this.items = dataelem;
      this.dataUploadLoading = false;

      if (res.data.weeksArray.length % this.itemsPerPage === 0) {
        this.itemsPerPage++;
      }

      this.currentPage = parseInt(
        res.data.weeksArray.length / this.itemsPerPage + 1
      );
    },
    generate_years() {
      let years = [];
      let current_year = new Date().getFullYear();
      for (let i = -4; i <= 0; i++) {
        years.push(current_year + i);
      }
      return years;
    },
    async uploadDataToAPI() {
      this.dataUploadLoading = true;
      const res = await axios.post(
        this.$store.state.apiUrl + this.apiRouteSet,
        // "/api/setDataUmsatzmonitor/setCompaniDataYear",
        {
          year: this.selectedYear,
          data: this.items,
          email: this.email,
        }
      );
      console.log(res);
      this.dataUploadLoading = false;
    },
  },
  mounted() {
    this.generate_items_array();
    this.years = this.generate_years();
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
