<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Bereits eingetragene Kalenderwochen Ihres Betriebes im Umsatzmonitor (
      {{ selectedYear }} )
    </v-card-title>
    <v-card-text v-if="!loadingData">
      <v-select
        v-if="showSelector"
        class="hide-at-print"
        v-model="selectedYear"
        :items="generateYearsToSelectArray()"
        solo
      />
      <v-container>
        <v-row>
          <v-col
            align="center"
            cols="1"
            :class="week.dataEntry"
            class="ma-1"
            v-for="week in statusDataEntrys"
            :key="week._id"
          >
            {{ week.kw }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      loadingData: true,
      apiPath: this.generateAPIPath(),
      statusDataEntrys: [],
      selectedYear: this.year,
    };
  },
  props: {
    email: {
      type: String,
      required: false,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    year: {
      type: Number,
      required: false,
      default: new Date().getFullYear(),
    },
    showSelector: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    selectedYear: function () {
      this.getStatusDataEntrys();
    },
    year: function () {
      this.selectedYear = this.year;
      this.getStatusDataEntrys();
    },
    email: function () {
      this.getStatusDataEntrys();
    },
  },
  methods: {
    generateAPIPath: function () {
      this.loadingData = true;
      if (this.isAdmin) {
        return "/api/admin/getCompaniDataYear";
      }
      return "/api/getDataUmsatzmonitor/getCompaniDataYear";
    },
    generateYearsToSelectArray: function () {
      let currentYear = new Date().getFullYear();
      let years = [];
      for (let i = 0; i < 6; i++) {
        years.push(currentYear - i);
      }
      return years;
    },

    getStatusDataEntrys: async function () {
      const res = await axios.post(this.$store.state.apiUrl + this.apiPath, {
        year: this.selectedYear,
        email: this.email,
      });
      //Generate Array withe entry for evry week for Year in current for iteration
      let singelYear = [];
      for (let y = 0; y < 53; y++) {
        singelYear.push({
          kw: y + 1,
          dataEntry: "error",
        });
      }
      //insetring entrys alredy made
      for (let y = 0; y < res.data.weeksArray.length; y++) {
        singelYear[res.data.weeksArray[y].kw - 1].dataEntry = "success";
      }
      this.statusDataEntrys = singelYear;
      this.loadingData = false;
      return;
    },
  },
  mounted: function () {
    this.getStatusDataEntrys();
  },
};
</script>
<style></style>
