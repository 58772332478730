import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

let theme = {
  themes: {
    light: {
      background: colors.green.lighten5,
      lightbg: colors.grey.base,
      darkbg: colors.grey.darken3,
      menuebg: "#088342",
    },
    dark: {
      background: colors.grey.darken4,
      lightbg: colors.grey.darken3,
      darkbg: colors.grey.darken3,
      menuebg: "#0F1D29",
    },
  },
};

if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  theme.dark = true;
}

export default new Vuetify({
  theme,
});
