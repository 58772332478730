<template>
  <v-app fluid :style="theme()">
    <span></span>
    <side-nav-bar
      v-if="this.$store.state.isLoggedIn"
      class="no-print"
    ></side-nav-bar>
    <v-main fluid>
      <v-container fill-height fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideNavBar from "./components/SideNavBar.vue";

export default {
  components: { SideNavBar },
  name: "App",

  data: () => ({
    //
  }),
  created() {
    if (this.$route.name != "Login") {
      this.$router.push({ name: "Login" });
    }

    if (this.getCookie("darkTheme") == "true") {
      this.$vuetify.theme.dark = true;
    } else if (this.getCookie("darkTheme") == "false") {
      this.$vuetify.theme.dark = false;
    }
  },
  methods: {
    theme() {
      if (this.$vuetify.theme.dark == true) {
        return { backgroundColor: this.$vuetify.theme.themes.dark.background };
      } else {
        return { backgroundColor: this.$vuetify.theme.themes.light.background };
      }
    },
    getCookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) return match[2];
    },
  },
};
</script>
<style>
html {
  overflow-y: auto;
}

@media print {
  .no-print {
    display: none !important;
  }
  html,
  body {
    height: 99%;
  }
}
</style>
