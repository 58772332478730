<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Entwicklung des eigenen Betriebes von {{ yearToCompare }} zu
      {{ selectedYear }}
    </v-card-title>
    <v-card-text v-if="!loadingData">
      <v-select
        class="hide-at-print"
        v-model="yearToCompare"
        :items="generateYearsToSelectArray(selectedYear - 1)"
        solo
      />
      <v-spacer />
      <custom-chart :chartdata="cahrtData" unit=" %" />
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
      yearToCompare: this.selectedYear - 1,
    };
  },
  components: {
    customChart,
  },
  props: {
    selectedUser: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    selectedUser: async function () {
      await this.setupChartData();
    },
    selectedYear: async function () {
      await this.setupChartData();
      if (this.selectedYear === this.yearToCompare) {
        this.yearToCompare = this.selectedYear - 1;
      }
    },
    yearToCompare: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/getCompaniDataCompTowYears";
      }
      return "/api/getDataUmsatzmonitor/getCompaniDataCompTowYears";
    },
    generateYearsToSelectArray: function () {
      let years = [];
      for (let i = 1; i < 8; i++) {
        years.push(this.selectedYear - i);
      }
      return years;
    },
    setupChartData: async function () {
      this.loadingData = true;

      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          yearToComp: this.yearToCompare,
          email: this.selectedUser.email,
        },
        labels: [
          "Umsatzentwicklung ",
          "Umsatzentwicklung in der Kategorie Obst, Gemüse und Kartoffeln",
          "Umsatzentwicklung in der Kategorie Sonstiges",
          "Entwicklung der Auftragszahlen",
        ],
        subVariable: [
          "growthSumme",
          "growthNettoUmsObstGemKartof",
          "growthNettoUmsSonstige",
          "growthNumberOfOrders",
        ],
      });
      //console.log(this.cahrtData);
      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },

  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
