<script>
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  data: () => ({}),
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    unit: {
      type: String,
      default: " €",
    },
    beginAtZero: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    chartdata: function () {
      const highestDatasetLength = Math.max(
        ...this.chartdata.datasets.map((dataset) => dataset.data.length)
      );
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        try {
          const averageValue =
            this.chartdata.datasets[i].data.reduce((a, b) => a + b, 0) /
            this.chartdata.datasets[i].data.length;
          this.chartdata.datasets[i].data[highestDatasetLength].push(
            averageValue
          );
        } catch (error) {
          console.log(this.chartdata);
          console.log(error);
        }
      }

      this.chartdata.labels.push("Gesamtdurchschnitt");

      this.renderChart(this.chartdata, this.optionsFunc());
    },
  },
  methods: {
    optionsFunc: function () {
      return {
        plugins: {
          datalabels: {
            color: "white",
            textAlign: "center",
            font: {
              weight: "bold",
              size: 16,
            },
          },
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                // Include a euro sign in the ticks
                callback: this.callBackForticks,
                beginAtZero: this.beginAtZero,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: this.callbackForLabel,
            afterLabel: this.callBackForAfterLabel,
          },
        },
      };
    },
    callBackForticks: function (value) {
      return Number(value).toLocaleString("de-DE") + this.unit;
    },
    callbackForLabel: function (tooltipItem) {
      return Number(tooltipItem.yLabel).toLocaleString("de-DE") + this.unit;
    },
    callBackForAfterLabel: function (tooltipItem) {
      if (
        this.chartdata.datasets[tooltipItem.datasetIndex].tooltipInfo !==
        undefined
      ) {
        return this.chartdata.datasets[tooltipItem.datasetIndex].tooltipInfo[
          tooltipItem.index
        ];
      }
      return null;
    },
  },
  mounted() {
    this.addPlugin(ChartDataLabels);
    if (!this.chartdata.labels.includes("Gesamtdurchschnitt")) {
      const highestDatasetLength = Math.max(
        ...this.chartdata.datasets.map((dataset) => dataset.data.length)
      );
      for (let i = 0; i < this.chartdata.datasets.length; i++) {
        try {
          const averageValue =
            this.chartdata.datasets[i].data.reduce((a, b) => a + b, 0) /
            this.chartdata.datasets[i].data.length;
          this.chartdata.datasets[i].data[highestDatasetLength] =
            Math.round(averageValue * 10) / 10;
        } catch (error) {
          console.log(this.chartdata);
          console.log(error);
        }
      }
      this.chartdata.labels.push("Gesamtdurchschnitt");
    }

    this.renderChart(this.chartdata, this.optionsFunc());
  },
};
</script>
