<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Entwicklung des eigenen Warenkorbwertes der letzten Jahre im Ø (max. 5)
    </v-card-title>
    <v-card-text v-if="!loadingData">
      <custom-chart :chartdata="cahrtData" :beginAtZero="false" />
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  name: "cart-value-years-comprison",
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  components: {
    customChart,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    email: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/getCompaniCartValueYear";
      }
      return "/api/getDataUmsatzmonitor/getCompaniCartValueYear";
    },
    setupChartData: async function () {
      this.loadingData = true;
      let currentYear = new Date().getFullYear();
      let dataReq = [];
      let prototypeDataReq = {
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: 0,
          email: this.email,
        },
        labels: [],
        subVariable: ["cartValue"],
      };

      for (let i = 0; i < 5; i++) {
        let singelDataReq = JSON.parse(JSON.stringify(prototypeDataReq));
        singelDataReq.apiQuerryData.year = currentYear - i;
        singelDataReq.labels = ["Ø Warenkorbwert " + (currentYear - i)];
        dataReq.push(singelDataReq);
      }
      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
