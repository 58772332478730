<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Entwicklung des eigenen Warenkorbwertes der letzten Jahre im Ø (max. 4)
      pro Quartal
    </v-card-title>
    <v-card-text v-if="!loadingData">
      <custom-bar-chart :chartdata="cahrtData"></custom-bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customBarChart from "../../components/customBarChart.vue";

export default {
  name: "cart-value-years-comparison-quartal",
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  components: {
    customBarChart,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    email: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/getCompaniCartValueYearQuartal";
      }
      return "/api/getDataUmsatzmonitor/getCompaniCartValueYearQuartal";
    },
    setupChartData: async function () {
      this.loadingData = true;
      let currentYear = new Date().getFullYear();
      let dataReq = [];
      let prototypeDataReq = {
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: 0,
          email: this.email,
        },
        labels: [],
        subVariable: ["cartValue"],
      };

      for (let i = 0; i < 4; i++) {
        let singelDataReq = JSON.parse(JSON.stringify(prototypeDataReq));
        singelDataReq.apiQuerryData.year = currentYear - i;
        singelDataReq.labels = ["Warenkorbwert: " + (currentYear - i)];
        dataReq.push(singelDataReq);
      }
      this.cahrtData = await generateChartData(dataReq, "Quartal ");
      this.loadingData = false;
    },
  },

  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
