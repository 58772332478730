<template>
  <v-navigation-drawer
    app
    expand-on-hover
    style="position: fixed; top: 0; left: 0"
    permanent
    :mini-variant.sync="mini"
    dark
    color="menuebg"
    overlay-color="red"
  >
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar color="darkbg">
          {{ getFirstLetters(this.$store.state.UserData.companyName) }}
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item link to="/AccountSettings">
        <v-list-item-content>
          <v-list-item-title>
            {{ this.$store.state.UserData.companyName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ this.$store.state.UserData.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list nav flat>
      <v-list-item
        link
        v-for="NavElement in NavElements"
        :key="NavElement.id"
        :to="NavElement.route"
      >
        <v-list-item-icon>
          <v-icon> {{ NavElement.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ NavElement.title }} </v-list-item-title>
      </v-list-item>
      <v-list-group v-if="showAdminMenue" prepend-icon="mdi-shield-key">
        <template v-slot:activator>
          <v-list-item-title>Admin</v-list-item-title>
        </template>
        <v-list-item
          dense
          v-for="adminNavElement in adminNavElements"
          :key="adminNavElement.id"
          :to="adminNavElement.route"
        >
          <v-list-item-icon>
            <v-icon v-text="adminNavElement.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="adminNavElement.title"></v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";

export default {
  name: "SideNavBar",
  data() {
    return {
      mini: true,
      showAdminMenue: false,
      NavElements: [
        {
          id: 1,
          route: "/",
          icon: "mdi-home",
          title: "Überblick",
        },
        {
          id: 2,
          route: "/evaluations",
          icon: "mdi-finance",
          title: "Auswertungen",
        },
        {
          id: 3,
          route: "/ImportData",
          icon: "mdi-folder-plus-outline ",
          title: "Neue Daten eintragen",
        },
        {
          id: 4,
          route: "/about",
          icon: "mdi-information",
          title: "About",
        },
        {
          id: 5,
          route: "/changepassword",
          icon: "mdi-lock-reset",
          title: "Passwort ändern",
        },
      ],
      adminNavElements: [
        {
          id: 1,
          route: "/useroverview",
          icon: "mdi-clipboard-list-outline",
          title: "Übersicht Nutzer",
        },
        {
          id: 2,
          route: "adminSettings",
          icon: "mdi-cog-outline",
          title: "Admin Einstellungen",
        },
        {
          id: 3,
          route: "/addUser",
          icon: "mdi-account-multiple-plus-outline",
          title: "Neuen Nutzer anlegen",
        },
        {
          id: 4,
          route: "/importDataAdmin",
          icon: "mdi-database-edit-outline",
          title: "Dateien eintragen für andere",
        },
        {
          id: 5,
          route: "/adminEvaluations",
          icon: "mdi-finance",
          title: "Admin Auswertung",
        },
        {
          id: 6,
          route: "/postToStartpage",
          icon: "mdi-note-alert",
          title: "Auf Startseite Posten",
        },
        {
          id: 7,
          route: "/chartGenerator",
          icon: "mdi-chart-box-plus-outline",
          title: "Diagrammgenerator",
        },
      ],
    };
  },
  mounted() {
    try {
      axios
        .post(this.$store.state.apiUrl + "/api/admin/requestAcces")
        .then((res) => {
          if (res.status === 200) {
            this.NavElements.splice(1, 2);
            this.showAdminMenue = true;
          }
        })
        .catch(() => {
          //its ok
        });
    } catch (e) {
      //nothing to catch
    }
  },
  methods: {
    getFirstLetters: function (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word[0])
        .join("");

      return firstLetters.substring(0, 3);
    },
  },
};
</script>

<style>
.v-list .v-list-item--active {
  color: lightskyblue !important;
}
</style>
