<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title>
      Datenvisualisierung der eigenen Daten aller Jahre (Maximal 7)
    </v-card-title>
    <v-card-text v-if="cahrtData != null">
      <custom-chart :chartdata="cahrtData"></custom-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import customChart from "../../components/customChart.vue";
import generateChartData from "../../utils/generateChartData";

export default {
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  components: {
    customChart,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    email: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/getCompaniDataYear";
      }
      return "/api/getDataUmsatzmonitor/getCompaniDataYear";
    },
    setupChartData: async function () {
      this.loadingData = true;

      let currentYear = new Date().getFullYear();
      let dataReq = [];
      let prototypeDataReq = {
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: 0,
          email: this.email,
        },
        labels: [],
        subVariable: ["summe"],
      };

      for (let i = 0; i < 7; i++) {
        let singelDataReq = JSON.parse(JSON.stringify(prototypeDataReq));
        singelDataReq.apiQuerryData.year = currentYear - i;
        singelDataReq.labels = ["Umsatz: " + (currentYear - i)];
        dataReq.push(singelDataReq);
      }
      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },
  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
