<script>
import { Line } from "vue-chartjs";
//import func from "vue-editor-bridge";

export default {
  extends: Line,
  data: () => ({}),
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    unit: {
      type: String,
      default: " €",
    },
    beginAtZero: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    chartdata: function () {
      this.renderChart(this.chartdata, this.optionsFunc());
    },
  },
  methods: {
    optionsFunc: function () {
      return {
        //responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                // Include a euro sign in the ticks
                callback: this.callBackForticks,
                beginAtZero: this.beginAtZero,
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: this.callbackForLabel,
            afterLabel: this.callBackForAfterLabel,
          },
        },
      };
    },
    callBackForticks: function (value) {
      return Number(value).toLocaleString("de-DE") + this.unit;
    },
    callbackForLabel: function (tooltipItem) {
      return Number(tooltipItem.yLabel).toLocaleString("de-DE") + this.unit;
    },
    callBackForAfterLabel: function (tooltipItem) {
      if (
        this.chartdata.datasets[tooltipItem.datasetIndex].tooltipInfo !==
        undefined
      ) {
        return this.chartdata.datasets[tooltipItem.datasetIndex].tooltipInfo[
          tooltipItem.index
        ];
      }
      return null;
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.optionsFunc());
  },
};
</script>
