<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col xl="5" lg="8" md="9">
        <v-card :loading="LoginFormLoading" class="sm-auto" ref="LoginCard">
          <v-toolbar dark>
            <v-toolbar-title>Login Formular</v-toolbar-title>
          </v-toolbar>
          <v-card-subtitle>
            {{ LoginInformationMessage }}
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="userData.email"
                prepend-icon="mdi-at"
                name="E-Mail"
                label="E-Mail"
                type="text"
                autocomplete="on"
                :rules="[(v) => !!v || 'Eingabe erforderlich']"
              ></v-text-field>
              <v-text-field
                v-model="userData.password"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Passwort"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :rules="[(v) => !!v || 'Eingabe erforderlich']"
                v-on:keyup.enter="loginUser()"
                autocomplete="on"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn dark block @click="loginUser()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export default {
  name: "Login",
  data() {
    return {
      LoginFormLoading: false,
      LoginFormHeaderColor: null,
      LoginInformationMessage: null,
      showPassword: false,
      userData: {
        email: "",
        password: "",
      },
      valid: true,
    };
  },
  methods: {
    async loginUser() {
      if (!this.$refs.form.validate()) {
        this.LoginInformationMessage = "Bitte füllen Sie beide Felder aus";
        return;
      }

      try {
        this.LoginFormLoading = true;
        const res = await axios.post(
          this.$store.state.apiUrl + "/api/users/login",
          this.userData
        );
        const { token } = res.data;
        setAuthToken(token);

        // Decode token to get user data
        const decoded = jwt_decode(token);
        this.$store.dispatch("setUser", decoded);
        this.LoginFormHeaderColor = "green";
        this.$router.push({ name: "Home" });
      } catch (err) {
        if (err.response === undefined) {
          this.LoginInformationMessage =
            "Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später erneut.";
          this.LoginFormLoading = false;
          this.LoginFormHeaderColor = "red";
        } else if (err.response.status === 400) {
          this.LoginInformationMessage = "Ihre Daten stimmen nicht Überein";
          this.LoginFormLoading = false;
          this.LoginFormHeaderColor = "red";
        }
      }
    },
  },
};
</script>

<style></style>
