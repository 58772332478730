import Vue from "vue";
import VueRouter from "vue-router";

//View Imports
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import ImportData from "../views/ImportData.vue";
import Evaluations from "../views/Evaluations.vue";
import About from "../views/About.vue";
import ChangePassword from "../views/ChangePassword.vue";
import AccountSettings from "../views/AccountSettings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/ImportData",
    name: "ImportData",
    component: ImportData,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/AccountSettings",
    name: "Account Settings",
    component: AccountSettings,
  },
  {
    path: "/evaluations",
    name: "Evaluations",
    component: Evaluations,
  },
  {
    path: "/adminSettings",
    name: "Admin Settings",
    component: () => import("../views/Admin/AdminSettings.vue"),
  },
  {
    path: "/useroverview",
    name: "User Overview",
    component: () => import("../views/Admin/userOverview.vue"),
  },
  {
    path: "/addUser",
    name: "Add new User",
    component: () => import("../views/Admin/RegisterNewUser.vue"),
  },
  {
    path: "/importDataAdmin",
    name: "Add data for other Users",
    component: () => import("../views/Admin/ImportData.vue"),
  },
  {
    path: "/adminEvaluations",
    name: "Get Admin Evaluations",
    component: () => import("../views/Admin/AdminEvaluations.vue"),
  },
  {
    path: "/postToStartpage",
    name: "Post to startpage",
    component: () => import("../views/Admin/PostToStartpage.vue"),
  },
  {
    path: "/chartGenerator",
    name: "Chart generator",
    component: () => import("../views/Admin/AdminChartGenerator.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
