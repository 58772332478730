<template>
  <v-card class="pa-4">
    <v-card-title>{{ post.title }}</v-card-title>
    <v-card-subtitle>
      <p v-if="post.subtitle != 'null'">{{ post.subtitle }}</p>
      <p>Von: {{ post.author }}</p>
    </v-card-subtitle>
    <v-img v-if="post.image" :src="post.imageUrl"> </v-img>
    <v-card-text v-if="post.content != 'null'" class="text--primary">
      {{ post.content }}</v-card-text
    >
    <v-card-actions>Veröffentlicht am: {{ post.datePublished }}</v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    post: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {},
  mounted: async function () {
    console.log(this.post);
    this.post.datePublished = new Date(
      this.post.datePublished
    ).toLocaleString();
  },
};
</script>
<style></style>
