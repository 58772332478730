import axios from "axios";
import store from "../store/index";

// Helperfunction to prepare data for chart
// dataReq -> Array of requests. (Objects)
// one Request should contain: {
//   1. apiQuerryPath
//   2. apiQuerryData
//   3. labels
//       -> should be an array
//   4. subVariable
//       -> should be an array
//       -> Wich variable of object in array weeksArray wich gets returned form API
//   5. tooltipInfo { (Optional)
//          variable: String
//          appendText: String
//      }
//}
const generateChartData = async function (
  dataReq,
  LabelText = "KW",
  fillInside = false
) {
  //checking type
  let colorCounter = 0;
  let datasetlength = [];

  const colorPresets = [
    "#4198D7",
    "#D8B655",
    "#7A61BA",
    "#46D39A",
    "#E55759",
    "#FFA056",
    "#8DDDD0",
  ];

  let chartDataElem = {
    labels: [],
    datasets: [],
  };

  // i: Iterate Datasets -> make API calls -> push them to right chart
  for (let i = 0; i < dataReq.length; i++) {
    const res = await axios.post(
      store.state.apiUrl + dataReq[i].apiQuerryPath,
      dataReq[i].apiQuerryData
    );

    if (res.data === null || res.data.weeksArray.length === 0) {
      continue;
    }

    for (let varPos = 0; varPos < dataReq[i].subVariable.length; varPos++) {
      let datasetPreset = {
        label: "undefined",
        data: null,
        tooltipInfo: [],
        fill: fillInside,
        borderColor: colorPresets[colorCounter],
        backgroundColor: colorPresets[colorCounter++],
        tension: 0.2,
        pointRadius: 1.5,
        borderWidth: 3,
      };

      datasetPreset.label = dataReq[i].labels[varPos];
      datasetPreset.data = [];
      let sortingIndex = "kw";
      if (res.data.weeksArray[0].kw === undefined) {
        sortingIndex = "_id";
      }
      for (
        let iterateData = 0;
        iterateData < res.data.weeksArray.length;
        iterateData++
      ) {
        datasetPreset.data[res.data.weeksArray[iterateData][sortingIndex] - 1] =
          res.data.weeksArray[iterateData][dataReq[i].subVariable[varPos]];
      }
      datasetPreset.tooltipInfo = [];
      if (dataReq[i].tooltipInfo !== undefined) {
        for (
          let iterateData = 0;
          iterateData < res.data.weeksArray.length;
          iterateData++
        ) {
          datasetPreset.tooltipInfo[
            res.data.weeksArray[iterateData][sortingIndex] - 1
          ] =
            res.data.weeksArray[iterateData][dataReq[i].tooltipInfo.variable] +
            dataReq[i].tooltipInfo.appendText;
        }
      }
      datasetlength.push(datasetPreset.data.length);
      chartDataElem.datasets.push(Object.assign({}, datasetPreset));
    }
  }

  if (chartDataElem.datasets.length === 0) {
    return null;
  }

  for (let i = 0; i < Math.max(...datasetlength); i++) {
    chartDataElem.labels.push(LabelText + (i + 1));
  }

  return chartDataElem;
};

export default generateChartData;
