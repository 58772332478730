<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col xl="5" lg="8" md="9">
        <v-card :loading="LoginFormLoading" ref="LoginCard">
          <v-toolbar :color="LoginFormHeaderColor" dark>
            <v-toolbar-title>Passwort ändern</v-toolbar-title>
          </v-toolbar>
          <v-card-subtitle>
            {{ LoginInformationMessage }}
          </v-card-subtitle>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="userData.currentPW"
                prepend-icon="mdi-lock"
                :append-icon="showCurrentPW ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showCurrentPW ? 'text' : 'password'"
                @click:append="showCurrentPW = !showCurrentPW"
                label="Momentanes Passwort"
                autocomplete="on"
              ></v-text-field>
              <v-text-field
                v-model="userData.newPW1"
                prepend-icon="mdi-shield-plus-outline"
                :append-icon="showNewPW ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPW ? 'text' : 'password'"
                autocomplete="new-password"
                @click:append="showNewPW = !showNewPW"
                label="Neues Passwort"
              ></v-text-field>
              <v-text-field
                v-model="userData.newPW2"
                prepend-icon="mdi-shield-plus-outline"
                :append-icon="showNewPW ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPW ? 'text' : 'password'"
                autocomplete="new-password"
                @click:append="showNewPW = !showNewPW"
                label="Neues Passwort bestätigen"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn dark block @click="changePasswordFunction()">
              Abschicken
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangePassword",
  data() {
    return {
      LoginFormLoading: false,
      LoginFormHeaderColor: null,
      LoginInformationMessage: null,
      showCurrentPW: false,
      showNewPW: false,
      userData: {
        email: "",
        currentPW: "",
        newPW1: "",
        newPW2: "",
      },
    };
  },
  methods: {
    async changePasswordFunction() {
      if (this.userData.newPW1 != this.userData.newPW2) {
        this.userData.newPW1 = "";
        this.userData.newPW2 = "";
        this.LoginInformationMessage =
          "Die beiden neuen Passwörter stimmen nicht überein";
        this.LoginFormHeaderColor = "red";
        return;
      } else {
        this.userData.newPW = this.userData.newPW1;
      }

      try {
        this.LoginFormLoading = true;
        await axios.post(
          this.$store.state.apiUrl + "/api/users/changePassword",
          this.userData
        );

        this.LoginFormHeaderColor = "green";
        this.$router.push({ name: "Home" });
      } catch (e) {
        this.LoginInformationMessage =
          "Ihr Passwort konnte nicht geändert werden, da die eingegebenen Daten inkorekt waren";
        this.LoginFormLoading = false;
        this.LoginFormHeaderColor = "red";
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
