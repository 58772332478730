<template>
  <v-card class="pa-4" :loading="loadingData">
    <v-card-title> Datenvisualisierung der eigenen Daten 2022 </v-card-title>
    <v-card-text v-if="!loadingData">
      <custom-chart :chartdata="cahrtData" />
    </v-card-text>
  </v-card>
</template>

<script>
import generateChartData from "../../utils/generateChartData";
import customChart from "../../components/customChart.vue";

export default {
  name: "cart-value-years-comparison-quartal",
  data() {
    return {
      cahrtData: null,
      apiPath: this.generateAPIPath(),
      loadingData: true,
    };
  },
  components: {
    customChart,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    selectedYear: {
      type: Number,
      required: true,
    },
  },
  watch: {
    email: async function () {
      await this.setupChartData();
    },
    selectedYear: async function () {
      await this.setupChartData();
    },
  },
  methods: {
    generateAPIPath: function () {
      if (this.isAdmin) {
        return "/api/admin/getCompaniDataYear";
      }
      return "/api/getDataUmsatzmonitor/getCompaniDataYear";
    },
    setupChartData: async function () {
      this.loadingData = true;
      let dataReq = [];
      dataReq.push({
        apiQuerryPath: this.apiPath,
        apiQuerryData: {
          year: this.selectedYear,
          email: this.email,
        },
        labels: [
          "Netto-Umsatz Obst, Gemüse und Kartoffeln",
          "Netto-Umsatz Sonstiges",
          "Summe aller Umsätze dieser Woche.",
        ],
        subVariable: ["nettoUmsObstGemKartof", "nettoUmsSonstige", "summe"],
      });

      this.cahrtData = await generateChartData(dataReq);
      this.loadingData = false;
    },
  },

  mounted: async function () {
    await this.setupChartData();
  },
};
</script>
<style></style>
