import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    UserData: {},
    apiUrl: process.env.VUE_APP_API_URL || "http://localhost:5000",
  },
  mutations: {
    setUser(state, user) {
      state.UserData = user;
      console.log("in Mutations");
    },
    setIsUserLoggedIn(state, condition) {
      state.isLoggedIn = condition;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit("setUser", user);
      context.commit("setIsUserLoggedIn", true);
    },
    deleteUser(context) {
      context.commit("setUser", {});
      context.commit("setIsUserLoggedIn", false);
    },
  },
  modules: {},
});
